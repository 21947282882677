import { useSearchParams } from 'react-router-dom';
import Asset from '../screens/Asset';
import { requestGet } from '../axios';

export default function AssetContainer(props) {
	let [ searchParams, setSearchParams ] = useSearchParams()

	async function getAsset(group_id, asset_type, page){
		try {
			const params = {'group_id': group_id, 'asset_type': asset_type === 'ALL' ? '' : asset_type, 'page': page}
			return await requestGet('/asset-list/', params)

		} catch (error) {
			return error
		}
	}

	return <Asset 
		{...props} 
		searchParams={searchParams}
		setSearchParams={setSearchParams}
		getAsset={getAsset} />
}
import { GoogleMap, LoadScript, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { useState } from 'react';
import { GMAPS_API_KEY } from '../config';

var numeral = require('numeral');


export default function BuildingAssetDetailView(props) {
	const [service, setService] = useState()
	const [targetLocation, setTargetLocation] = useState()
	const [showMarker, setShowMarker] = useState()
	const [map, setMap] = useState()


	function statusTag(value) {
		const statusMap = {
			'ACTIVE': 'p-2 bg-green-500 text-xs text-white rounded',
			'INACTIVE': 'p-2 bg-gray-500 text-xs text-white rounded',
		}

		return <div className={statusMap[value]}>{value}</div>
	}

	function getDistance(lat1, lat2, lng1, lng2) {
		lng1 = lng1 * Math.PI / 180
		lng2 = lng2 * Math.PI / 180
		lat1 = lat1 * Math.PI / 180
		lat2 = lat2 * Math.PI / 180

		// Haversine formula
		let dlng = lng2 - lng1
		let dlat = lat2 - lat1
		let a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlng / 2), 2)
		let c = 2 * Math.asin(Math.sqrt(a))
		let r = 6371
		return `${numeral(c * r).format('0.00')} km`
	}

	function fetchGooglePlaces(type) {
		setService(type)

		let target_location = []
		const service = new window.google.maps.places.PlacesService(map)

		const request = {
			location: {lat: props.data.detail.lat, lng: props.data.detail.lng},
			radius: 5000,
			type: [type]
		}

		service.nearbySearch(request, (results, status) => {
			if (status === 'OK') {
				for (let item of results) {
					target_location.push({
						'type': type,
						'name': item.name,
						'lat': item.geometry.location.lat(),
						'lng': item.geometry.location.lng(),
						'address': item.vicinity,
						'distance': getDistance(props.data.lat, item.geometry.location.lat(), props.data.lng, item.geometry.location.lng()),
						'icon': 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png'
					})
				}
			}
		
			setTargetLocation(target_location)
		})
	}

	return (
		<>
			<div className="grid grid-cols-1 mb-5 gap-y-5 md:grid-cols-3 md:gap-5">
				<div className="bg-white p-5 rounded">
					<div className="flex-col space-y-5">
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Asset Type</div>
							<div className="text-gray-500 font-bold">{ props.data.asset_type }</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Total Sum Insured</div>
							<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_tsi).format()}`}</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Total Premium</div>
							<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_premium).format()}`}</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Average Premium Rate</div>
							<div className="text-gray-500 font-bold">{numeral(props.data.ave_rate).format('0.000')}%</div>
						</div>
					</div>
				</div>
				<div className="col-span-2">
					<div className="flex flex-col min-h-full h-80">
						<div className="flex-1 bg-white row-span-2 p-3 rounded mb-3">
							<LoadScript googleMapsApiKey={GMAPS_API_KEY} libraries={['places']}>
								<GoogleMap
									onLoad={map => setMap(map)}
									mapContainerStyle={{ width: '100%', height: '100%' }}
									center={{
										lat: props.data.detail.lat,
										lng: props.data.detail.lng
									}}
									zoom={13}>
									<MarkerF
										onClick={() => setShowMarker(props.data.name)}
										position={{ lat: props.data.detail.lat, lng: props.data.detail.lng }}>

										{showMarker === props.data.name ?
											<InfoWindowF onCloseClick={() => setShowMarker('')}>
												<div>
													<div style={{ marginBottom: 5, fontWeight: 'bold' }}>{props.data.name}</div>
												</div>
											</InfoWindowF>
										: null}
									</MarkerF>

									{targetLocation ?
										targetLocation.map(item => {
											return (
												<MarkerF
													key={item.name}
													onClick={() => setShowMarker(item.name)}
													icon={{ url: item.icon }}
													position={{ lat: item.lat, lng: item.lng }}>

													{props.showMarker === item.name ?
														<InfoWindowF onCloseClick={() => this.setState({ showMarker: '' })}>
															<div>
																<div style={{ marginBottom: 5, fontWeight: 'bold' }}>{item.name}</div>
																<div>{item.address}</div>
																<div>{item.distance}</div>
															</div>
														</InfoWindowF>
														: null}
												</MarkerF>
											)
										})
									: null}
								</GoogleMap>
							</LoadScript>
						</div>
						<div className="flex-none bg-white p-3 rounded">
							<div className="grid grid-cols-3 gap-5">
								<button 
									onClick={() => fetchGooglePlaces('fire_station')} 
									className={service === 'fire_station' ? 'p-3 rounded text-white bg-gray-800' : 'p-3 rounded text-white bg-gray-500 hover:bg-gray-800'}>
										Fire Station
								</button>
								<button 
									onClick={() => fetchGooglePlaces('police')} 
									className={service === 'police' ? 'p-3 rounded text-white bg-gray-800' : 'p-3 rounded text-white bg-gray-500 hover:bg-gray-800'}>
									Police
								</button>
								<button 
									onClick={() => fetchGooglePlaces('hospital')} 
									className={service === 'hospital' ? 'p-3 rounded text-white bg-gray-800' : 'p-3 rounded text-white bg-gray-500 hover:bg-gray-800'}>
									Hospital
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-white p-5 rounded mb-5">
				<div className="grid grid-cols-1 gap-y-5 md:grid-cols-5 md:gap-5">
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Area</div>
						<div className="text-gray-500 font-bold">{props.data.detail.area}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Occupation</div>
						<div className="text-gray-500 font-bold">{props.data.detail.occupation}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">EQ Zone</div>
						<div className="text-gray-500 font-bold">{props.data.detail.zone}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Latitude</div>
						<div className="text-gray-500 font-bold">{numeral(props.data.detail.lat).format('0.0000')}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Longitude</div>
						<div className="text-gray-500 font-bold">{numeral(props.data.detail.lng).format('0.0000')}</div>
					</div>
				</div>
			</div>

			<div className="bg-white rounded p-5 mb-5">
				<div class="overflow-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th width="5%" className="p-3 border-2">No</th>
								<th className="p-3 border-2">Policy No</th>
								<th className="p-3 border-2">Product</th>
								<th className="p-3 border-2">Period</th>
								<th className="p-3 border-2">Rate</th>
								<th className="p-3 border-2">Total Sum Insured</th>
								<th className="p-3 border-2">Total Premium</th>
								<th width="10%" className="p-3 border-2">Status</th>
							</tr>
						</thead>
						<tbody>
							{props.data.policy.data.map((item, n) => {
								return (
									<tr key={n.toString()} className="hover:bg-gray-100">
										<td className="text-sm text-center text-gray-500 p-3 border-2">{n + 1}</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{item.policy_no ? item.policy_no : '-'}</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{item.product}</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">
											<div>{item.start_date}</div>
											<div>{item.end_date}</div>
										</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{item.rate_percent}%</td>
										<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{numeral(item.tsi).format()}</span></td>
										<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{numeral(item.nominal).format()}</span></td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{statusTag(item.status)}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}
import axios from "axios";
import { API_URL } from './config';

export function requestGet(endpoint, params=null){
	let config = {
		method: "get",
		url: API_URL + endpoint,
		params: params,
	}
	return axios(config)
}
import React, { Component } from 'react';
import { Loader } from '../components/utils';
import { PieChart } from '../components/charts';

var numeral = require('numeral');

export default class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			group_id: localStorage.getItem('group_id') ? parseInt(localStorage.getItem('group_id')) : 1
		}
	}

	componentDidMount() {
		this.getDashboardData()
	}

	getDashboardData() {
		this.props.getDashboard(this.state.group_id).then(response => {
			if (response.data.code === '00') {			
				this.setState({ 
					data: response.data.data,
				})
			}
		})
	}

	assetTypeChart() {
		return {
			labels: this.state.data.asset_type.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: this.state.data.asset_type.data.map(item => item.tsi),
				},
			]
		}
	}

	assetValueChart() {
		return {
			labels: this.state.data.asset_value.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: this.state.data.asset_value.data.map(item => item.tsi),
				},
			]
		}
	}

	assetClaimChart() {
		return {
			labels: this.state.data.asset_claim.data.map(item => item.name),
			datasets: [
				{
				  label: 'Total Claim',
				  data: this.state.data.asset_claim.data.map(item => item.total_claim_amount),
				},
			]
		}
	}

	claimTypeChart() {
		return {
			labels: this.state.data.claim_type.data.map(item => item.name),
			datasets: [
				{
				  label: 'Total Claim',
				  data: this.state.data.claim_type.data.map(item => item.total_claim_amount),
				},
			]
		}
	}

	claimStatusChart() {
		return {
			labels: this.state.data.claim_status.data.map(item => item.name),
			datasets: [
				{
				  label: 'Total Claim',
				  data: this.state.data.claim_status.data.map(item => item.total_claim_amount),
				},
			]
		}
	}

	render() {
		return (
			<>
				{!this.state.data ?
					<Loader />
					:
					<>
						<div className="bg-white rounded p-5 mb-5">
							<h1 className="text-2xl text-gray-500 font-bold">Summary Dashboard</h1>
						</div>

						<div className="bg-white rounded p-5 grid mb-5 md:grid-cols-4 gap-5">
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Total Sum Insured</div>
								<div className="text-gray-500 font-bold">{`Rp ${numeral(this.state.data.total_tsi).format()}`}</div>
							</div>
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Total Premium</div>
								<div className="text-gray-500 font-bold">{`Rp ${numeral(this.state.data.total_premium).format()}`}</div>
							</div>
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Total Paid Claim</div>
								<div className="text-gray-500 font-bold">{`Rp ${numeral(this.state.data.total_claim_paid).format()}`}</div>
							</div>
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Claim Ratio (%)</div>
								<div className="text-gray-500 font-bold">{numeral(100 * this.state.data.claim_ratio).format('0.00')}</div>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-4 gap-5">
						<div className="bg-white p-3 rounded">
								<PieChart
									data={this.assetTypeChart()}
									showLegend={false}
									title="Asset Type"
								/>
							</div>
							<div className="bg-white p-3 rounded">
								<PieChart
									data={this.assetValueChart()}
									showLegend={false}
									title="Asset Value"
								/>
							</div>
							<div className="bg-white p-3 rounded">
								<PieChart
									data={this.assetClaimChart()}
									showLegend={false}
									title="Asset Claim"
								/>
							</div>
							<div className="bg-white p-3 rounded">
								<PieChart
									data={this.claimTypeChart()}
									showLegend={false}
									title="Claim Type"
								/>
							</div>
							<div className="bg-white p-3 rounded">
								<PieChart
									data={this.claimStatusChart()}
									showLegend={false}
									title="Claim Status"
								/>
							</div>
						</div>
					</>
				}
			</>
		)
	}
}

import React, { Component } from 'react';
import { Loader } from '../components/utils';
import AssetDetailsBuilding from '../components/AssetDetailsBuilding';
import AssetDetailsMotorbike from '../components/AssetDetailsMotorbike';


export default class Asset extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			showMarker: '',
			group_id: localStorage.getItem('group_id') ? parseInt(localStorage.getItem('group_id')) : 1,
			asset_id: this.props.searchParam.get('asset_id'),
			target_location: []
		}
	}

	componentDidMount() {
		this.getAssetDetail()
	}

	getAssetDetail() {
		this.props.getAssetDetail(this.state.group_id, this.state.asset_id).then(response => {
			if (response.data.code === '00') {
				this.setState({
					data: response.data.data
				})
			}
		})
	}

	render() {
		return (
			<>
				{!this.state.data ?
					<Loader />
					:
					<>
						<div className="bg-white rounded p-5 mb-5">
							<h1 className="text-2xl text-gray-500 font-bold">{this.state.data.name}</h1>
						</div>

						{this.state.data.asset_type === 'BUILDING' ?
							<AssetDetailsBuilding data={this.state.data} />
						: this.state.data.asset_type === 'MOTORBIKE' ?
							<AssetDetailsMotorbike data={this.state.data} />
						: null}
					</>
				}
			</>
		)
	}
}

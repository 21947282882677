import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import AssetDetail from '../screens/AssetDetail';
import { requestGet } from '../axios';

export default function AssetDetailContainer(props) {
	let [searchParam, setSearchParam] = useSearchParams()

	async function getAssetDetail(group_id, asset_id){
		try {
			const params = {'group_id': group_id, 'asset_id': asset_id}
			return await requestGet('/asset-list/detail/', params)

		} catch (error) {
			return error
		}
	}

	return <AssetDetail 
		{...props} 
		searchParam={searchParam}
		setSearchParam={setSearchParam}
		getAssetDetail={getAssetDetail} />
}
import React, { Component } from 'react';
import { Loader } from '../components/utils';
import 'chart.js/auto';

export default class Admin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			group_id: localStorage.getItem('group_id') ? parseInt(localStorage.getItem('group_id')) : 1
		}
	}

	componentDidMount() {
		this.getGroupList()
	}

	getGroupList() {
		this.props.getGroupList().then(response => {
			if (response.data.code === '00') {
				this.setState({ 
					data: response.data.data,
				})
			}
		})
	}

	setLocalData(group_id) {
		localStorage.setItem('group_id', group_id)
		this.setState({group_id: group_id})
	}

	render() {
		return (
			<>
				{!this.state.data ?
					<Loader />
					:
					<>
						<div className="bg-white rounded p-5 mb-5">
							<h1 className="text-2xl text-gray-500 font-bold">Group List</h1>
						</div>

						<div className="grid grid-cols-3 gap-5 md:grid-cols-6">
							{this.state.data.map(item => {
								return (
									<button key={item.id}
										className={this.state.group_id === item.id ? "bg-red-500 p-5 rounded" : "bg-gray-300 p-5 rounded"} 
										onClick={() => this.setLocalData(item.id)}>
											<div className={this.state.group_id === item.id ? "text-white text-center" : "text-gray text-center"}>{ item.name }</div>
									</button>
								)
							})}
						</div>
					</>
				}
			</>
		)
	}
}

import React, { Component } from 'react';
import { Loader } from '../components/utils';
import 'chart.js/auto';

var numeral = require('numeral');

export default class Claim extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			group_id: localStorage.getItem('group_id') ? parseInt(localStorage.getItem('group_id')) : 1
		}
	}

	componentDidMount() {
		this.getClaimData()
	}

	getClaimData() {
		this.props.getClaim(this.state.group_id).then(response => {
			if (response.data.code === '00') {
				this.setState({ 
					data: response.data.data,
				})
			}
		})
	}

	statusTag(value) {
		const statusMap = {
			'WAITING DOCUMENT': 'p-2 bg-gray-500 text-xs text-white rounded',
			'ON REVIEW': 'p-2 bg-yellow-500 text-xs text-white rounded',
			'WAITING ACCEPTANCE': 'p-2 bg-blue-500 text-xs text-white rounded',
			'WAITING PAYMENT': 'p-2 bg-blue-500 text-xs text-white rounded',
			'SETTLED': 'p-2 bg-green-500 text-xs text-white rounded',
			'REJECTED': 'p-2 bg-red-500 text-xs text-white rounded',
		}
		return  <div className={statusMap[value]}>{ value }</div>
	}

	render() {
		return (
			<>
				{!this.state.data ?
					<Loader />
					:
					<>
						<div className="bg-white rounded p-5 mb-5">
							<h1 className="text-2xl text-gray-500 font-bold">Claim List</h1>
						</div>

						<div className="bg-white rounded p-5 mb-5 grid grid-cols-1 gap-y-5 md:grid-cols-4 md:gap-5 ">
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Total Sum Insured</div>
								<div className="text-gray-500 font-bold">{`Rp ${numeral(this.state.data.total_tsi).format()}`}</div>
							</div>
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Total Premium</div>
								<div className="text-gray-500 font-bold">{`Rp ${numeral(this.state.data.total_premium).format()}`}</div>
							</div>
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Total Paid Claim</div>
								<div className="text-gray-500 font-bold">{`Rp ${numeral(this.state.data.total_claim_paid).format()}`}</div>
							</div>
							<div className="bg-sky-100 p-3 rounded">
								<div className="text-gray-500">Claim Ratio (%)</div>
								<div className="text-gray-500 font-bold">{numeral(100 * this.state.data.claim_ratio).format('0.00')}</div>
							</div>
						</div>

						<div className="bg-white rounded p-5 mb-5">
							<div class="overflow-x-scroll">
								<table className="w-full">
									<thead>
										<tr>
											<th className="p-3 border-2">No</th>
											<th className="p-3 border-2">Insured</th>
											<th className="p-3 border-2">Type</th>
											<th className="p-3 border-2">Period</th>
											<th className="p-3 border-2">Date of Loss</th>
											<th className="p-3 border-2">Cause of Loss</th>
											<th className="p-3 border-2">Description</th>
											<th className="p-3 border-2">TotalClaim</th>
											<th className="p-3 border-2">Status</th>
										</tr>
									</thead>
									<tbody>
									{this.state.data.claim_list.map((item, n)  => {
										return (
											<tr key={n.toString()} className="hover:bg-gray-100">
												<td className="text-sm text-center text-gray-500 p-3 border-2">{n + 1}</td>
												<td className="text-sm text-left text-gray-500 p-3 border-2">{ item.insured_name }</td>
												<td className="text-sm text-left text-gray-500 p-3 border-2">{ item.product_name }</td>
												<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.period_start } - { item.period_end }</td>
												<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.date_of_loss }</td>
												<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.cause_of_loss }</td>
												<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.description }</td>
												<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{ numeral(item.total_claim).format() }</span></td>
												<td className="text-sm text-center text-gray-500 p-3 border-2">{this.statusTag(item.status)}</td>
											</tr>
										)
									})}
									</tbody>
								</table>
							</div>
						</div>
					</>
				}
			</>
		)
	}
}

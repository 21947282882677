import { GoogleMap, LoadScript, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { useState } from 'react';
import { GMAPS_API_KEY } from '../config';

var numeral = require('numeral');


export default function BuildingAssetDetailView(props) {
	function statusTag(value) {
		const statusMap = {
			'ACTIVE': 'p-2 bg-green-500 text-xs text-white rounded',
			'INACTIVE': 'p-2 bg-gray-500 text-xs text-white rounded',
		}

		return <div className={statusMap[value]}>{value}</div>
	}

	return (
		<>
			
			<div className="bg-white p-5 rounded mb-5">
				<div className="grid grid-cols-1 mb-5 gap-5 md:grid-cols-4 md:gap-5">
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Asset Type</div>
						<div className="text-gray-500 font-bold">{ props.data.asset_type }</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Total Sum Insured</div>
						<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_tsi).format()}`}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Total Premium</div>
						<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_premium).format()}`}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Average Premium Rate</div>
						<div className="text-gray-500 font-bold">{numeral(props.data.ave_rate).format('0.000')}%</div>
					</div>

					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Area</div>
						<div className="text-gray-500 font-bold">{props.data.detail.area}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Plat</div>
						<div className="text-gray-500 font-bold">{props.data.detail.plat}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Zone</div>
						<div className="text-gray-500 font-bold">{props.data.detail.zone}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Frame No</div>
						<div className="text-gray-500 font-bold">{props.data.detail.frame}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Machine No</div>
						<div className="text-gray-500 font-bold">{props.data.detail.machine}</div>
					</div>
				</div>
			</div>

			<div className="bg-white rounded p-5 mb-5">
				<div class="overflow-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th width="5%" className="p-3 border-2">No</th>
								<th className="p-3 border-2">Policy No</th>
								<th className="p-3 border-2">Product</th>
								<th className="p-3 border-2">Period</th>
								<th className="p-3 border-2">Rate</th>
								<th className="p-3 border-2">Total Sum Insured</th>
								<th className="p-3 border-2">Total Premium</th>
								<th width="10%" className="p-3 border-2">Status</th>
							</tr>
						</thead>
						<tbody>
							{props.data.policy.data.map((item, n) => {
								return (
									<tr key={n.toString()} className="hover:bg-gray-100">
										<td className="text-sm text-center text-gray-500 p-3 border-2">{n + 1}</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{item.policy_no ? item.policy_no : '-'}</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{item.product}</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">
											<div>{item.start_date}</div>
											<div>{item.end_date}</div>
										</td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{item.rate_percent}%</td>
										<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{numeral(item.tsi).format()}</span></td>
										<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{numeral(item.nominal).format()}</span></td>
										<td className="text-sm text-center text-gray-500 p-3 border-2">{statusTag(item.status)}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}
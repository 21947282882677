import React from 'react';
import { useNavigate } from 'react-router-dom';
import Group from '../screens/Group';
import { requestGet } from '../axios';

export default function GroupContainer(props) {
	let navigate = useNavigate()

	async function getGroupList(){
		try {
			return await requestGet('/group-list/')

		} catch (error) {
			return error
		}
	}

	return <Group 
		{...props} 
		navigate={navigate}
		getGroupList={getGroupList} />
}
import React, { Component } from 'react';
import { Loader, Button } from '../components/utils';
import { DefaultAssetView, BuildingAssetView, MotorBikeAssetView } from '../components/asset';



export default class Asset extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			showMarker: '',
			group_id: localStorage.getItem('group_id') ? parseInt(localStorage.getItem('group_id')) : 1,
			asset_type_list: ['ALL'],
			asset_type: this.props.searchParams.get('type') ? this.props.searchParams.get('type') : 'ALL',
			page: this.props.searchParams.get('page') ? this.props.searchParams.get('page') : 1
		}
	}

	componentDidMount() {
		this.getAssetData()
	}

	getAssetData() {
		this.props.getAsset(this.state.group_id, this.state.asset_type, this.state.page).then(response => {
			if (response.data.code === '00') {
				this.setState({ 
					data: response.data.data,
					asset_type_list: [...new Set(this.state.asset_type_list.concat(response.data.data.asset_type_list))]
				})
			}
		})
	}

	async updateAssetType(item) {
		await this.setState({asset_type: item, data: null})
		this.props.setSearchParams({'type': item})
		this.getAssetData()
	}

	render() {
		return (
			<>
				{!this.state.data ?
					<Loader />
					:
					<>
						<div className="bg-white rounded p-5 mb-5">
							<h1 className="text-2xl text-gray-500 font-bold">Asset List</h1>
						</div>
						
						<div className="flex-row space-x-5 mb-5">
							{this.state.asset_type_list.map(item => {
								return (
									<Button
										key={item} 
										item={item} 
										active={this.state.asset_type === item} 
										onClick={() => this.updateAssetType(item)} />
								)
							})}
						</div>

						{this.state.asset_type === 'ALL' ?
							<DefaultAssetView data={this.state.data} />
						: this.state.asset_type === 'BUILDING' ?
							<BuildingAssetView data={this.state.data} />
						: this.state.asset_type === 'MOTORBIKE' ?
							<MotorBikeAssetView data={this.state.data} />
						:null}

					</>
				}
			</>
		)
	}
}

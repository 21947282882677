import { useState, useCallback } from 'react';
import { GoogleMap, LoadScript, MarkerF, InfoWindowF, useJsApiLoader } from '@react-google-maps/api';
import { PieChart } from './charts';
import { GMAPS_API_KEY } from '../config';
import { Pagination } from './utils';

var numeral = require('numeral');

export function DefaultAssetView(props) {
	function assetTypeChart() {
		return {
			labels: props.data.asset_type.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: props.data.asset_type.data.map(item => item.tsi),
				  borderWidth: 1,
				},
			]
		}
	}

	function productChart() {
		return {
			labels: props.data.product.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: props.data.product.data.map(item => item.tsi),
				  borderWidth: 1,
				},
			]
		}
	}

	return (
		<>
			<div className="grid grid-cols-1 gap-y-5 mb-5 md:grid-cols-3 md:gap-5">
				<div className="bg-white p-5 rounded">
					<div className="flex-col space-y-5">
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Total Sum Insured</div>
							<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_tsi).format()}`}</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Total Premium</div>
							<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_premium).format()}`}</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Average Premium Rate</div>
							<div className="text-gray-500 font-bold">{numeral(props.data.ave_rate).format('0.000')}%</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">No of Asset</div>
							<div className="text-gray-500 font-bold">{props.data.count_asset}</div>
						</div>
					</div>
				</div>

				<div className="bg-white p-3 rounded">
					<PieChart
						data={assetTypeChart()}
						showLegend={false}
						title="Asset Type"
					/>
				</div>
				<div className="bg-white p-3 rounded">
					<PieChart
						data={productChart()}
						showLegend={false}
						title="Insurance Type"
					/>
				</div>
			</div>
		</>
	)
} 

export function BuildingAssetView(props) {
	const [showMarker, setShowMarker] = useState('')
	const [currentLoc, setCurrentLoc] = useState({
		lat: props.data.asset_list.data[0].lat, 
		lng: props.data.asset_list.data[0].lng
	})

	function assetValueChart() {
		return {
			labels: props.data.asset_value.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: props.data.asset_value.data.map(item => item.tsi),
				  borderWidth: 1,
				},
			]
		}
	}

	function occupationChart() {
		return {
			labels: props.data.occupation.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: props.data.occupation.data.map(item => item.tsi),
				  borderWidth: 1,
				},
			]
		}
	}

	function productChart() {
		return {
			labels: props.data.product.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: props.data.product.data.map(item => item.tsi),
				  borderWidth: 1,
				},
			]
		}
	}

	return (
	<>
		<div className="grid grid-cols-1 gap-y-5 mb-5 md:grid-cols-3 md:gap-5">
			<div className="bg-white p-5 rounded">
				<div className="flex-col space-y-5">
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Total Sum Insured</div>
						<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_tsi).format()}`}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Total Premium</div>
						<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_premium).format()}`}</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">Average Premium Rate</div>
						<div className="text-gray-500 font-bold">{numeral(props.data.ave_rate).format('0.000')}%</div>
					</div>
					<div className="bg-sky-100 p-3 rounded">
						<div className="text-gray-500">No of Asset</div>
						<div className="text-gray-500 font-bold">{props.data.count_asset}</div>
					</div>
				</div>
			</div>
			<div className="col-span-2 bg-white p-3 rounded min-h-full h-80">
				<LoadScript googleMapsApiKey={GMAPS_API_KEY}>
					<GoogleMap
						mapContainerStyle={{width:'100%', height:'100%'}}
						center={currentLoc}
						zoom={15}>
							{props.data.asset_list.data.map(item => {
								return (
									<MarkerF
										key={item.asset_id.toString()} 
										onClick={() => setShowMarker(item.asset_id)}
										position={{lat: item.lat, lng: item.lng}}>
									
										{showMarker === item.asset_id ?
										<InfoWindowF  
											zIndex={1000}
											onCloseClick={() => setShowMarker('')}>
												<div>
													<div style={{marginBottom: 5, fontWeight: 'bold'}}>
														<a className="text-blue-500 hover:text-blue-800" href={`./detail/?asset_id=${ item.asset_id }`}>{ item.name }</a>
													</div>
													<div>Total Sum Insured</div>
													<div style={{marginBottom: 5, fontWeight: 'bold', color:'grey'}}>Rp {numeral(item.tsi).format()}</div>
													<div>Total Premium</div>
													<div style={{marginBottom: 5, fontWeight: 'bold', color:'grey'}}>Rp {numeral(item.premium).format()}</div>
												</div>
										</InfoWindowF>
										: null}
									</MarkerF>
								)
							})}
					</GoogleMap>
				</LoadScript>
			</div>
		</div>
		
		<div className="grid mb-5 md:grid-cols-3 gap-5">
			<div className="bg-white p-3 rounded">
				<PieChart
					data={assetValueChart()}
					showLegend={false}
					title="Asset Value"
				/>
			</div>

			<div className="bg-white p-3 rounded">
				<PieChart
					data={occupationChart()}
					showLegend={false}
					title="Occupation"
				/>
			</div>

			<div className="bg-white p-3 rounded">
				<PieChart
					data={productChart()}
					showLegend={false}
					title="Insurance Type"
				/>
			</div>
		</div>

		<div className="bg-white rounded p-5 mb-5">
			<div className="overflow-x-scroll">
				<table className="w-full">
					<thead>
						<tr>
							<th className="p-3 border-2">No</th>
							<th className="p-3 border-2">Code</th>
							<th className="p-3 border-2">Name</th>
							<th className="p-3 border-2">Address</th>
							<th className="p-3 border-2">Occupation</th>
							<th className="p-3 border-2">Zone</th>
							<th className="p-3 border-2">Total Sum Insured</th>
							<th className="p-3 border-2">Total Premium</th>
						</tr>
					</thead>
					<tbody>
					{props.data.asset_list.data.map((item, n)  => {
						return (
							<tr key={n.toString()} className="hover:bg-gray-100">
								<td className="text-sm text-center text-gray-500 p-3 border-2">{n + 1}</td>
								<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.code }</td>
								<td className="text-sm text-left text-gray-500 p-3 border-2">
									<a className="text-blue-500 hover:text-blue-800" href={`./detail/?asset_id=${ item.asset_id }`}>{ item.name }</a>
								</td>
								<td className="text-sm text-left text-gray-500 p-3 border-2">{ item.address }</td>
								<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.occupation }</td>
								<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.zone }</td>
								<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{ numeral(item.tsi).format() }</span></td>
								<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{ numeral(item.premium).format() }</span></td>
							</tr>
						)
					})}
					</tbody>
				</table>
			</div>
		</div>
	</>
	)
} 

export function MotorBikeAssetView(props) {
	function assetValueChart() {
		return {
			labels: props.data.asset_value.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: props.data.asset_value.data.map(item => item.tsi),
				  borderWidth: 1,
				},
			]
		}
	}

	function productChart() {
		return {
			labels: props.data.product.data.map(item => item.name),
			datasets: [
				{
				  label: 'TSI',
				  data: props.data.product.data.map(item => item.tsi),
				  borderWidth: 1,
				},
			]
		}
	}

	return (
		<>
			<div className="grid grid-cols-1 gap-y-5 mb-5 md:grid-cols-3 md:gap-5">
				<div className="bg-white p-5 rounded">
					<div className="flex-col space-y-5">
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Total Sum Insured</div>
							<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_tsi).format()}`}</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Total Premium</div>
							<div className="text-gray-500 font-bold">{`Rp ${numeral(props.data.total_premium).format()}`}</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">Average Premium Rate</div>
							<div className="text-gray-500 font-bold">{numeral(props.data.ave_rate).format('0.000')}%</div>
						</div>
						<div className="bg-sky-100 p-3 rounded">
							<div className="text-gray-500">No of Asset</div>
							<div className="text-gray-500 font-bold">{props.data.count_asset}</div>
						</div>
					</div>
				</div>

				<div className="bg-white p-3 rounded">
					<PieChart
						data={assetValueChart()}
						showLegend={false}
						title="Asset Type"
					/>
				</div>
				<div className="bg-white p-3 rounded">
					<PieChart
						data={productChart()}
						showLegend={false}
						title="Insurance Type"
					/>
				</div>
			</div>

			<div className="bg-white rounded p-5 mb-5">
				{props.data.asset_list.pages > 1 ?
					<Pagination 
						pages={props.data.asset_list.pages}  
						total={props.data.asset_list.total} />
				: null}

				<div className="overflow-x-scroll">
					<table className="w-full">
						<thead>
							<tr>
								<th className="p-3 border-2">No</th>
								<th className="p-3 border-2">Code</th>
								<th className="p-3 border-2">Name</th>
								<th className="p-3 border-2">Plat</th>
								<th className="p-3 border-2">No Rangka</th>
								<th className="p-3 border-2">No Mesin</th>
								<th className="p-3 border-2">Zone</th>
								<th className="p-3 border-2">Total Sum Insured</th>
								<th className="p-3 border-2">Total Premium</th>
							</tr>
						</thead>
						<tbody>
						{props.data.asset_list.data.map((item, n)  => {
							return (
								<tr key={n.toString()} className="hover:bg-gray-100">
									<td className="text-sm text-center text-gray-500 p-3 border-2">{n + 1}</td>
									<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.code }</td>
									<td className="text-sm text-left text-gray-500 p-3 border-2">
										<a className="text-blue-500 hover:text-blue-800" href={`./detail/?asset_id=${ item.asset_id }`}>{ item.name }</a>
									</td>
									<td className="text-sm text-left text-gray-500 p-3 border-2">{ item.plat }</td>
									<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.frame }</td>
									<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.machine }</td>
									<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.zone }</td>
									<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{ numeral(item.tsi).format() }</span></td>
									<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{ numeral(item.premium).format() }</span></td>
								</tr>
							)
						})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';

var numeral = require('numeral');

export function PieChart(props) {
	return (
		<Chart
			type='pie'
			data={props.data}
			plugins={[ChartDataLabels]}
			options={{
				plugins: {
					legend: {
						display: props.showLegend,
						position: 'top',
						align: 'center',
						labels: {
							font: {
								size: 10
							}
						}
					},
					title: {
						display: true,
						text: props.title
					},
					datalabels: {
						align: 'end',
						anchor: 'center',
						color: '#000',
						formatter: function (value, context) {
							return `${numeral(100 * value / context['dataset']['data'].reduce((a, b) => a + b, 0)).format('0.00')}%`
						},
					}
				}
			}} />
	)
}
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Claim from '../screens/Claim';
import { requestGet } from '../axios';

export default function ClaimContainer(props) {
	let navigate = useNavigate()

	async function getClaim(group_id){
		try {
			const params = {'group_id': group_id}
			return await requestGet('/claim-list/', params)

		} catch (error) {
			return error
		}
	}

	return <Claim 
		{...props} 
		navigate={navigate}
		getClaim={getClaim} />
}
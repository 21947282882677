import React, { Component } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Dashboard from './containers/Dashboard';
import Claim from './containers/Claim';
import Asset from './containers/Asset';
import AssetDetail from './containers/AssetDetail';
import Group from './containers/Group';


class Apps extends Component {
	constructor(props){
		super(props)
		this.state = {
			openMenu: false
		}
	}

	openMenuAction() {
		this.setState({openMenu: !this.state.openMenu})
	}

	render() {
		return (
			<div className="min-h-screen bg-slate-100">
				<div className="flex bg-main items-center px-5 py-3"> 
					<div className="mr-3"><img className="h-auto w-14" src="https://content.weplus.id/production/logo/asri-logo.png" /></div> 
					<div className="text-white text-lg">Insurance Asset Management</div>
				</div>
				
				<div className="md:flex min-h-screen">
					<div className="hidden md:block basis-1/6 bg-gray-800 p-5">
						<Link 
							className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
							to={"/"}>Dashboard
						</Link>
						<Link 
							className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
							to={"/asset/"}>Assets
						</Link>
						<Link 
							className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
							to={"/claim/"}>Claims
						</Link>
						{/* <Link 
							className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
							to={"/group/"}>Group List
						</Link> */}
					</div>
					
					{this.state.openMenu ?
						<div className="bg-gray-800 p-5 md:hidden">
							<Link 
								className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
								to={"/"}>Dashboard
							</Link>
							<Link 
								className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
								to={"/asset/"}>Assets
							</Link>
							<Link 
								className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
								to={"/claim/"}>Claims
							</Link>
							{/* <Link 
								className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
								to={"/group/"}>Group List
							</Link> */}
						</div>
					: null}

					<div className="flex-1 p-5 text-left md:hidden" onClick={() => this.openMenuAction()}> 
						<FontAwesomeIcon icon={faBars} /> Menu
					</div> 

					<div className="flex-1 md:basis-5/6 p-5">
						<Routes>
							<Route path={'/'} element={<Dashboard />} />
							<Route path={'/claim/'} element={<Claim />} />
							<Route path={'/asset/'} element={<Asset />} />
							<Route path={'/asset/detail/'} element={<AssetDetail />} />
							<Route path={'/group/'} element={<Group />} />
						</Routes>
					</div>
				</div>
			</div>
		);
	}
}

export default Apps;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../screens/Dashboard';
import { requestGet } from '../axios';

export default function DashboardContainer(props) {
	let navigate = useNavigate()

	async function getDashboard(group_id){
		try {
			const params = {'group_id': group_id}
			return await requestGet('/summary/', params)

		} catch (error) {
			return error
		}
	}

	return <Dashboard 
		{...props} 
		navigate={navigate}
		getDashboard={getDashboard} />
}